import { Fragment } from "react";

// ! Hooks
import useWindowDimensions from "hooks/useWindowDimension";
import { useTrans } from "locales/hook";
import { useCallback } from "react";

// ! Components
import HLimitLongString from "components/LimitLongString";
import MoneyWithLocale from "components/Money/locale";

// ! Helpers
import { GetCurrencyCode, alignmentText, formatNumber, linkTypeInTable, valueHandler } from "helpers/helpers";

interface IProps {
    data: Array<any>;
    headers: Array<any>;
    canReadMACost: boolean;
    headingRows: any;
    subHeadingRows: any;
    dataLink: any;
    shop: string;
    isReportPNL: boolean;
    dependdata?: any
}

enum FormatType {
    LINK = 'link',
    NUMBERLINK = 'numberlink',
    MONEY = "money",
    NUMBERDAY = "numberday",
    NUMBERHOUR = "numberhour",
    TREEVIEW = "treeview",
}

function BodyLine(props: IProps) {
    const {
        data,
        headers,
        canReadMACost,
        headingRows,
        subHeadingRows,
        dataLink,
        shop,
        isReportPNL,
        dependdata
    } = props;
    const t = useTrans();

    const { width } = useWindowDimensions();
    const currencyCode = GetCurrencyCode();

    const displayColumnByFormat = useCallback((payload) => {
        let { value, body, id, subId } = payload;

        const format = headers[subId]?.dataFormat || "text";

        const name = headers[subId]?.name || "";

        if (
            ["CosAmountOpening", "CosAmountClosing"].includes(name) &&
            !canReadMACost
        ) {
            return <></>;
        }

        let firstColumn = subId === 0 ? "hrv-report-first-column" : "";

        if (subHeadingRows.includes(id) && subId === 0) {
            firstColumn += " hrv-report-pl-32";
        }

        let link = "";

        let clickable = false;
        let displayByTime = "";

        switch (format) {
            case FormatType.LINK:
                // * là Link
                clickable = true;

                link = `https://${shop}/admin/${linkTypeInTable(name)}/${dataLink[id][subId]
                    }`;

                if (dataLink[id][subId] === 0) {
                    clickable = false;
                    if (name === "ProductId" || name === "VariantId") {
                        if (value === "--") {
                            value = t("Optional_product");
                        } else {
                            value = t(value.trim());
                        }
                    }
                }

                if (value === "--" || value === "Hệ thống" || value === 'guest' || value === 'redeem') {
                    clickable = false;
                    if (value === "redeem") {
                        value = t("redeem");
                    }
                }

                if (name === "CustomerId") {
                    if (value === "guest") {
                        value = t("guest");
                    }
                }

                break;

            case FormatType.NUMBERLINK:
                // * là numberLink
                clickable = true;

                if (value !== 0) {
                    link = `https://${shop}/admin/shipments${dataLink[id][subId]}`;
                } else {
                    clickable = false;
                }
                break;

            case FormatType.NUMBERDAY:
                displayByTime = `${formatNumber(value)} ${t("ngày")}`;
                break;

            case FormatType.NUMBERHOUR:
                displayByTime = `${value} ${t("giờ")}`;
                break;

            case FormatType.TREEVIEW:
                const getName = data[id][subId];

                let isLastChild = dependdata[id][subId] === 'false'

                if (getName === "--") {
                    value = getName
                } else {
                    let findName = isLastChild ? `(${t("ProductByCategoryId")} ` + getName + ")" : getName;

                    value = findName
                }
                break;

            default:
                if (name === "CustomerType") {
                    value = t(value);
                }

                if (name === "CustomerGender") {
                    value = t(value);
                }

                if (name === "DiscountMethod") {
                    value = t(`DiscountMethod_value_${value}`)
                }

                if (name === "DiscountType") {
                    value = t(`DiscountType_value_${value}`)
                }
                if (name === "CarrierStatus") {
                    value = t(`CarrierStatus_value_${value}`)
                }
                if (name === "CarrierCODStatus") {
                    value = t(`CarrierCODStatus_value_${value}`)
                }
                if (name === "CustomerAgeGroup") {
                    value = t(`CustomerAgeGroup_value_${value}`)
                }
                if (name === "CustomerSegment") {
                    value = t(`CustomerSegment_value_${value}`)
                }
                if (name === "Taxrate" && value === "Không chịu thuế") {
                    value = t('Không chịu thuế')
                }

                break;
        }

        if (width < 650 && firstColumn.length) {
            firstColumn += " hrv-report-first-column-responsive"
        };

        const contentCell = () => {
            switch (format) {
                case FormatType.LINK:
                    return <HLimitLongString
                        text={value}
                        customClass={`hrv-report-transparent-bottom hrv-report-text-left hrv-report-mb-0 hrv-report-fs-14 hrv-report-table-col-pre ${clickable
                            ? "hrv-report-text-color-blue-2 hrv-report-cursor-pointer"
                            : ""
                            } ${alignmentText(format)}`}
                        isLink={clickable}
                        link={link}
                        lengthText={30}
                        placement="bottom"
                    />

                case FormatType.NUMBERLINK:
                    return <HLimitLongString
                        text={formatNumber(value)}
                        customClass={`hrv-report-transparent-bottom hrv-report-text-left hrv-report-mb-0 hrv-report-fs-14 hrv-report-table-col-pre ${clickable ? "hrv-report-cursor-pointer" : ""
                            } ${alignmentText(format)}`}
                        isLink={clickable}
                        link={link}
                        lengthText={30}
                        isLinkSecondary
                        tooltipSecondary={t("Xem vận đơn")}
                        placement={subId === body.length - 1 ? "right" : "bottom"}
                    />

                case FormatType.MONEY:
                    return <MoneyWithLocale
                        value={value}
                        currencyCode={currencyCode}
                        className="hrv-report-transparent-bottom hrv-report-text-right"
                    />

                case FormatType.NUMBERDAY:
                    return <HLimitLongString
                        customClass={`hrv-report-transparent-bottom hrv-report-mb-0 ${alignmentText(
                            format,
                        )}`}
                        text={displayByTime}
                    />

                case FormatType.NUMBERHOUR:
                    return <HLimitLongString
                        customClass={`hrv-report-transparent-bottom hrv-report-mb-0 ${alignmentText(
                            format,
                        )}`}
                        text={displayByTime}
                    />

                case FormatType.TREEVIEW:

                    return <HLimitLongString
                        text={value}
                        customClass={`hrv-report-transparent-bottom hrv-report-text-left hrv-report-mb-0 hrv-report-fs-14 hrv-report-table-col-pre ${alignmentText(format)}`}
                        isLink={false}
                        lengthText={300}
                        placement={subId === body.length - 1 ? "right" : "bottom"}
                    />

                default:
                    return <HLimitLongString
                        customClass={`hrv-report-transparent-bottom hrv-report-mb-0 hrv-report-table-col-pre ${alignmentText(
                            format,
                        )} ${valueHandler(format, name, value) === "infin"
                            ? "hrv-report-fs-22"
                            : ""
                            }`}
                        text={valueHandler(format, name, value)}
                        isLink={clickable}
                        lengthText={isReportPNL ? width < 650 ? 30 : 1000 : 30}
                        middleEllipsis={name === "ProductCategoryId"}
                    />
            }
        }

        return <td
            key={`td_table_r_${id}_${subId}`}
            className={`${firstColumn}`}
        >
            {contentCell()}
        </td>

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, headers]);

    return (
        <>
            {data?.map((body, id) => {
                let trClass = "";
                if (headingRows.includes(id)) {
                    trClass =
                        "hrv-report-transparent-bottom hrv-report-fw-500 hrv-report-text-color-black-6";
                }

                return (
                    <tr
                        key={`tr_table_r_${id}`}
                        className={`${trClass} hrv-report-active_hover`}
                    >

                        {body.map((value, subId) => {
                            return <Fragment key={`${id}_${subId}`}>
                                {displayColumnByFormat({
                                    value, body, id, subId,
                                })}
                            </Fragment>
                        })}
                    </tr>
                );
            })}


        </>
    )
}

export default BodyLine;