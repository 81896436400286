import { useFetchJson } from "@haravan/reactapp";
import md5 from "helpers/md5";
import useSWR from "swr";

const REPORT_URI =
  import.meta.env.VITE_APP_API_SERVICES + `/call/com_api/reports/`;
const REPORT_URI_V2 = import.meta.env.VITE_APP_API_SERVICES + `/reports/`;

export const REPORT_V2_URI = import.meta.env.VITE_APP_API_SERVICES + `/reports`;

const useHRVSWR: any = useSWR;

export function useReportList(tab) {
  const fetchJson = useFetchJson();
  const { data, isLoading, isValidating } = useHRVSWR(
    `${REPORT_URI}${tab}`,
    fetchJson,
  );

  return {
    data: data !== undefined ? data.data[0]?.lstReportScreen : [],
    isLoading,
    isValidating,
  };
}

export function useInfo(id) {
  const fetchJson = useFetchJson();
  return useHRVSWR(`${REPORT_URI}info/${id}`, fetchJson);
}

export function useSummary(id, body) {
  const key = md5(JSON.stringify(body));
  const fetchJson = useFetchJson();
  const fetcher = (url: string) =>
    fetchJson(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  return useHRVSWR(`${REPORT_URI}${id}/summary?k=${key}`, fetcher);
}

export function useQuery(id, body) {
  const key = md5(JSON.stringify(body));
  const fetchJson = useFetchJson();
  const fetcher = (url: string) =>
    fetchJson(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  let url = `${REPORT_URI}${id}/query?k=${key}`;
  return useHRVSWR(url, fetcher);
}

// && Referecence: https://swr.vercel.app/docs/conditional-fetching.en-US#conditional
export function useQueryV2(id, body, preventFetch = false, cacheClient = false, use: any = []) {
  const fetchJson = useFetchJson();

  const key = md5(JSON.stringify(body));

  const fetcher = (url: string) =>
    fetchJson(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  let url = `${REPORT_URI_V2}${id}/query?${cacheClient ? "key" : "k"}=${key}`;

  return useHRVSWR(preventFetch ? null : url, fetcher, {
    revalidateOnFocus: false,
    refreshInterval: 0,
  });
}

// && Referecence: https://swr.vercel.app/docs/conditional-fetching.en-US#conditional
export function useSummaryV2(id, body, preventFetch = false) {
  const key = md5(JSON.stringify(body));
  const fetchJson = useFetchJson();
  const fetcher = (url: string) =>
    fetchJson(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  return useHRVSWR(
    preventFetch ? null : `${REPORT_URI_V2}${id}/summary?key=${key}`,
    fetcher,
    {
      revalidateOnFocus: false,
      refreshInterval: 0,
    },
  );
}

