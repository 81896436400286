import { useTrans } from "@haravan/reactapp";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { LIMIT_GROUPED_BY_DISCOUNT } from "components/Metrics";
import * as ChartUtils from "helpers/chartutils";
import {
  FormatDateTimeCustom,
  FormatMoneyLocale,
  formatCurrency,
  formatNumber,
  limitString,
} from "helpers/helpers";
import useWindowDimensions from "hooks/useWindowDimension";
import { useRef } from "react";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

ChartUtils.RegisterTooltipPositioner();

interface IData {
  labels: Array<string>;
  data: Array<any>;
  namefield: string;
}

interface IProps {
  isDisplayTitle?: boolean;
  title?: string;
  proData: IData;
  colorBar: string;
  hoverColorBar?: string;
  typeHorizonal?: string;
  typeVertical?: string;
  configHeight?: number;
  unit?: string;
  indexAxis?: string;
  width?: number;
  isGrouped?: boolean
  isStacked?: boolean;
  reverseNegative?: boolean
}

function BarChart(props: IProps) {
  const {
    isDisplayTitle,
    title,
    proData,
    colorBar,
    hoverColorBar,
    typeHorizonal,
    configHeight = 0,
    typeVertical = "",
    unit,
    indexAxis = "x",
    isGrouped = false,
    isStacked = false,
    reverseNegative = false
  } = props;
  const refChart = useRef<any>();
  const t = useTrans();
  const { width: widthScreen } = useWindowDimensions();
  const getOrCreateTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector(
      "div.tooltip-container",
    );

    if (!tooltipEl) {
      tooltipEl = document.createElement("div");
      tooltipEl.classList.add("tooltip-container");
      tooltipEl.style.opacity = 1;
      tooltipEl.style.pointerEvents = "none";
      tooltipEl.style.transform = "translate(-50%, 0)";
      tooltipEl.style.transition = "all .1s ease";
      tooltipEl.style.padding = "12px 12px 2px 12px";

      const table = document.createElement("table");
      table.style.margin = "0px";
      table.style.width = "100%";

      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
  };
  const externalTooltipHandler = (context) => {
    // Tooltip Element
    const { chart, tooltip } = context;


    const tooltipEl = getOrCreateTooltip(chart);
    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    if (tooltip.body) {
      const titleLines = tooltip.title || [];
      const bodyLines = tooltip.body.map((b) => b.lines);

      const tableHead = document.createElement("thead");
      titleLines.forEach((title) => {
        const tr = document.createElement("tr");
        tr.style.borderWidth = "0px";

        const th = document.createElement("th");
        th.style.borderWidth = "0px";
        th.style.paddingBottom = "8px";
        th.style.fontSize = "14px";
        th.style.fontWeight = "500";
        let text = document.createTextNode(title ? title?.replaceAll(",", " ") : "");

        th.appendChild(text);
        th.classList.add("tooltip-container-header");
        tr.appendChild(th);
        tableHead.appendChild(tr);
      });

      const tableBody = document.createElement("tbody");

      bodyLines.forEach((_, i) => {
        const colors = tooltip.labelColors[i];
        const dataPoint = tooltip.dataPoints[i];

        const span = document.createElement("span");
        span.style.background = colors.backgroundColor;
        span.style.borderColor = colors.borderColor;
        span.style.marginRight = "10px";
        span.style.height = "12px";
        span.style.width = "12px";
        span.style.borderRadius = "2px";
        span.style.display = "inline-block";

        const tr = document.createElement("tr");
        tr.style.backgroundColor = "inherit";
        tr.style.borderWidth = "0px";

        const td = document.createElement("td");
        td.style.borderWidth = "0px";

        let value = dataPoint.raw;

        if (typeHorizonal === "money") {
          value = `${reverseNegative && value > 0 ? "-" : ""}${FormatMoneyLocale(value)}`;
        }

        if (typeHorizonal === "percent") {
          value = formatNumber(value, false) + "%";
        }

        if (typeHorizonal === "hour") {
          value = "0";
        }

        if (typeHorizonal === "number") {
          let unitHandler = unit === "OrderCountSpecial" ? "đơn" : "";

          value = formatNumber(value, false) + " " + unitHandler;
        }

        if (typeHorizonal === "") {
          value = "0";
        }

        const labelCurrentContainer = document.createElement("div");
        labelCurrentContainer.classList.add("hrv-report-d-flex");
        labelCurrentContainer.classList.add("hrv-report-mb-10");
        labelCurrentContainer.classList.add("hrv-report-w-100");
        labelCurrentContainer.innerHTML = `
            <div class="hrv-report-d-flex hrv-report-items-center hrv-report-w-100">
              ${span.outerHTML}
              <div class="hrv-report-mb-0 hrv-report-fs-14 hrv-report-fw-400 hrv-report-mr-20">${isGrouped ? proData.data[i].label : proData.namefield}</div>
              <div class="hrv-report-mb-0 hrv-report-fs-14 hrv-report-fw-400 hrv-report-ml-auto">${value}</div>
            </div>
          `;

        td.appendChild(labelCurrentContainer);
        tr.appendChild(td);
        tableBody.appendChild(tr);
      });

      const tableRoot = tooltipEl.querySelector("table");

      // Remove old children
      while (tableRoot.firstChild) {
        tableRoot.firstChild.remove();
      }

      // Add new children
      tableRoot.appendChild(tableHead);
      tableRoot.appendChild(tableBody);

      const { offsetTop: positionY } = chart.canvas;

      // Display, position, and set styles for font
      tooltipEl.style.opacity = 1;
      const { caretY } = chart.tooltip;
      const { chartArea } = chart;
      const { x: xActive } = chart.tooltip._active[0].element;
      const { x } = chart.tooltip._eventPosition

      //* Tooltip for Desktop
      if (widthScreen > 758) {
        switch (indexAxis) {
          case "x": //! Trục đứng
            if (!isGrouped) {
              if (tooltip.xAlign === "right") {
                // Bên phải
                tooltipEl.style.left = xActive - tooltipEl.clientWidth / 2 + "px";
              } else {
                // Bên trái
                tooltipEl.style.left = xActive + tooltipEl.clientWidth / 2 + "px";
              }
            } else {
              //! TH: là Grouped Chart
              if (x > widthScreen / 2) {
                tooltipEl.style.left = xActive - tooltipEl.clientWidth / 2 + "px";
              } else {
                tooltipEl.style.left = xActive + tooltipEl.clientWidth / 2 + "px";
              }
            }

            if (tooltip.caretY > chartArea.bottom) {
              tooltipEl.style.opacity = 0;
            }

            if (caretY + tooltipEl.clientHeight > configHeight) {
              tooltipEl.style.top = tooltip.caretY - tooltipEl.clientHeight + "px";
            } else {
              tooltipEl.style.top = positionY + tooltip.caretY + "px";
              tooltipEl.style.font = tooltip.options.bodyFont.string;
            }

            break;

          case "y": //! Trục ngang
            const widthOfTooltip = tooltipEl.offsetWidth;
            let widthOfWrapperChart = 0;

            if (refChart && refChart.current) {
              widthOfWrapperChart = refChart.current.clientWidth;
            }

            const isFloatRight = x + widthOfTooltip > widthOfWrapperChart - x / 2 + 240;

            if (isFloatRight) {
              // Bên phải
              tooltipEl.style.left =
                x - tooltipEl.clientWidth / 2 + "px";
            } else {
              // Bên trái
              tooltipEl.style.left =
                x + tooltipEl.clientWidth / 2 + "px";
            }

            tooltipEl.style.top = positionY + tooltip.caretY + "px";
            tooltipEl.style.font = tooltip.options.bodyFont.string;

            if (caretY + tooltipEl.clientHeight > configHeight) {
              tooltipEl.style.top = tooltip.caretY - tooltipEl.clientHeight + "px";
            } else {
              tooltipEl.style.top = positionY + tooltip.caretY + "px";
              tooltipEl.style.font = tooltip.options.bodyFont.string;
            }
            break;

          default:
        }
      }
      //* Tooltip for Mobile
      else {
        switch (indexAxis) {
          case "x": //! Trục đứng
            tooltipEl.style.left = widthScreen / 2 + "px";
            tooltipEl.style.top = 30 + "px";

            break;

          case "y": //! Trục ngang
            tooltipEl.style.left = tooltipEl.clientWidth / 2 + "px";

            if (caretY < configHeight / 2) {
              tooltipEl.style.top = configHeight / 2 + "px";

            } else {
              tooltipEl.style.top = tooltipEl.clientHeight + "px";
            }

            break;

          default:
        }
      }
    }
  };

  const labels = proData?.labels?.map((value) => {
    let format = "DD/MM/YYYY";

    if (typeVertical === "Hour") {
      format = "DD/MM/YYYY HH:mm";
    }
    if (typeVertical === "Day") {
      format = "DD/MM";
    }
    if (typeVertical === "Month") {
      format = "MM/YYYY";
    }

    if (typeVertical === "Year") {
      format = "YYYY";
    }

    if (typeVertical === "DiscountId") {
      return t(value);
    }

    if (!["Hour", "Day", "Month", "Year", "timestamp"].includes(typeVertical)) {
      return value;
    }

    return FormatDateTimeCustom(value, format);
  });

  const options = {
    indexAxis,
    responsive: true,
    maintainAspectRatio: false,
    // aspectRatio: 3,
    interaction: {
      mode: "index",
      intersect: true,
    },
    plugins: {
      legend: {
        display: false,
        position: "bottom" as const,
      },
      title: {
        display: isDisplayTitle,
        text: title,
      },
      tooltip: {
        enabled: false,
        position: "cursor",
        external: externalTooltipHandler,
      },
    },
    scales: {
      x: {
        border: {
          dash: [1, 1],
        },
        grid: {
          display: indexAxis === "y" ? true : false,
          drawTicks: true,
          offset: false,
        },
        ticks: {
          color: "#111827",
          callback: function (value, index, values) {
            let render = value;

            if (indexAxis === "x") {
              return limitString(labels[render]);
            } else {
              if (typeHorizonal === "money") {
                render = formatCurrency(value * (reverseNegative ? -1 : 1));
              }

              if (typeHorizonal === "percent") {
                render = formatNumber(value);
              }

              return render;
            }
          },
        },
      },
      y: {
        stacked: isStacked,
        border: {
          dash: [1, 1],
        },
        grid: {
          display: indexAxis === "y" ? false : true,
          drawTicks: true,
          offset: false,
        },
        ticks: {
          // Sử dụng callback để thêm đơn vị vào giá trị nhãn trục Y
          callback: function (value, index, values) {
            let render = value;

            if (indexAxis === "y") {
              return limitString(labels[render]);
            } else {
              if (typeHorizonal === "money") {
                render = `${reverseNegative && value > 0 ? "-" : ""}${formatCurrency(value)}`;
              }

              if (typeHorizonal === "percent") {
                render = formatNumber(value);
              }

              return render;
            }
          },
          color: "#111827",
        },

      },

    },
  } as any;
  const data = {
    labels: labels,
    datasets: isGrouped ? proData.data : [{
      label: proData.namefield,
      data: proData.data,
      backgroundColor: colorBar,
      hoverBackgroundColor: hoverColorBar,
      maxBarThickness: 76,
      borderRadius: indexAxis === "y" ? 6 : 2,
      categoryPercentage: indexAxis === "y" ? (proData.data.slice(0, LIMIT_GROUPED_BY_DISCOUNT).length * 0.7) / LIMIT_GROUPED_BY_DISCOUNT : undefined, // Nếu bar nằm ngang thì cấu hình width nhỏ
      barPercentage: indexAxis === "y" ? 1.0 : undefined// Nếu bar nằm ngang thì cấu hình width nhỏ
    }],
  };

  return (
    <div
      style={{
        width: "100%",
        height: configHeight,
      }}
      ref={refChart}
    >
      <Bar options={options} data={data} />
    </div>
  );
}

export default BarChart