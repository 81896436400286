/**
 * Header: Tiêu đề cho Template Report
 */

import MoneyWithLocale from "components/Money/locale";
import { GetCurrencyCode, isEmpty, setPrintDescription } from "helpers/helpers";
import { renderToString } from "react-dom/server";

const TEMPLATE_TAGS_BY_REPORT = {
    stock_transactionhistory_alltime: ["LocId", "ProductCategoryId"],
    stock_transactionhistory_adv: ["LocId", "ProductCategoryId"],
    stock_invreceive_by_suppliers: ["LocationId", "SupplierId"],
    sales_order_assigned_location: ["OrderAssignedLocationId"],
    payments_deb_supplier: ['SupplierId'],
    payments_deb_customer: ['CustomerId'],
    default: ["LocationId"]
}

export const HeaderReport = ({
    selectFilter,
    filters,
    reportNameSystem,
    name,
    dateRange,
    translate,
    summary
}: {
    selectFilter: any,
    filters: any[],
    reportNameSystem: string,
    name: string,
    dateRange: any,
    translate: Function,
    summary: any
}) => {
    const { startdate, enddate } = dateRange;
    const currencyCode = GetCurrencyCode();

    let description = setPrintDescription({
        selectFilter,
        filters,
        template: TEMPLATE_TAGS_BY_REPORT,
        reportNameSystem
    });

    let descriptionTag = "";

    const ShowMoreInfo = () => {
        return <>
            {summary &&
                <div className="hrv-report-mb-6">
                    <div className="hrv-report-row">
                        {summary.map((line, index) => {
                            return <div
                                className="hrv-report-col-6 hrv-report-d-flex hrv-report-justify-center hrv-report-items-center hrv-report-text-center hrv-report-mb-1 hrv-report-my-4"
                                key={index}
                            >
                                <span className="hrv-report-mr-2">{translate(line.name)}</span>

                                <b>
                                    <MoneyWithLocale
                                        value={line.value}
                                        currencyCode={currencyCode}
                                        className="hrv-report-mx-3"
                                    />
                                </b>
                            </div>
                        })}
                    </div>
                </div>
            }
        </>
    }

    if (!isEmpty(description)) {
        Object.keys(description).forEach((key) => {
            descriptionTag += `<div class="hrv-report-mb-8 hrv-report-text-center">${translate(key)}: ${!description[key].length ? translate("tất cả") : translate(description[key])}</div>`
        })
    }

    return `<h5 class="hrv-report-mb-8 hrv-report-fw-600 hrv-report-text-center">${name}</h5>
    <div class="hrv-report-mb-8 hrv-report-text-center">${translate("Từ ngày")} ${startdate} ${translate("đến ngày")} ${enddate}</div>
    
    ${descriptionTag}

    ${renderToString(<ShowMoreInfo />)}
   `
}