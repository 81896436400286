// ! Components
// import EmptyTemplate from "components/EmptyTemplate";
import Metrics from "components/Metrics/Metrics";
import MetricsV2 from "components/Metrics/MetricsV2";
import { SalesMetrics } from "components/Metrics/SalesMetrics";
import MetricsDiscount from "components/MetricsDiscount";
import PanelFilter from "components/PanelFilter";
import PanelStatus from "components/PanelStatus";
import TableReport from "components/Table";
import { TableExport } from "components/Table/TableByDefault/export/tableExport";

import { getListDisplayFilter } from "helpers/helpers";
import { useTableUtils } from "helpers/tableutils";
import { SCORE_CARD } from "pages/ReportStandard/context/store/constants";

import { METRICS, SALES_METRICS } from "components/Metrics/constants";

interface IProps {
  reportNameSystem: string;
  canReadMACost: boolean;
  headers: any;
  data: any;
  dataLink?: any;
  summary: Array<any>;
  onChangeDate: any;
  dateEnd: any;
  dateStart: any;
  isLoadingTable: boolean;
  groupBy?: any;
  groupAreas?: any;
  onHandleCheckboxChart?: any;
  measure?: any;
  groupBySelected?: any;
  measureSelected?: any;
  onHandleSortTable?: any;
  sortValue?: any;
  optionDimension?: any;
  onHandleChangeDimension?: any;
  onHandleSubmitFilter?: any;
  onHandleOnchangeQuery?: any;
  filterTag?: any;
  onHandleClose?: any;
  onChangeSearchQuery?: any;
  onChangeOperator?: any;
  onClickExport?: any;
  displayChartMode: any;
  onChangePage: any;
  handleChangeGroupBy: any;
  handleChangeColumnShowModal: any;
  isHiddenPagination: boolean;
  isAccessReportDiscount: any;
  compareDateEnd?: string;
  compareDateStart?: string;
  onChangeCompareDate?: Function;
  query?: any;
  queryPeriod?: any;
  isLoadingChart?: boolean;
  optionMeasure?: any;
  onChangeYAxis?: any;
  discount?: any;
  autoCompleteFilterPanel?: any;
  isLoadingAutoCompleteFilterPanel?: boolean;
  onHandleChangeFilter?: any;
  benchmark?: any;
  optionsHeatMap?: any;
  onChangeHeatmaps?: any;
  onHandleChangeCheckboxInFilterTypeSingle: Function;
  groupByFilterPanel: any;
  onHandleSubmitFilterWithDimension: Function;
  reportId?: string | number | undefined;
  reportName?: string;
  exportId?: number | string;
  optionCompare?: string;
  handlePrintApi?: any;
  dataPrint?: any,
  isLoadingPrint?: boolean,
  submitFilterV2?: any,
  dataExport?: any,
  onHandleTriggerGroupByAndFilter?: any,
  dependdata: any
  dataFetchingTable: any,
  onHandleCloseFilterByDimension: Function,
  measureDraw: any,
  onHandleChangeChartType: Function,
  summaryDrilldown: any
}

export default function BoilerTemplate(props: IProps) {
  const {
    reportNameSystem,
    canReadMACost,
    headers,
    data,
    dataLink,
    summary,
    onChangeDate,
    dateEnd,
    dateStart,
    isLoadingTable,
    groupBy,
    groupAreas,
    onHandleCheckboxChart,
    measure,
    groupBySelected,
    measureSelected,
    onHandleSortTable,
    sortValue,
    optionDimension,
    filterTag,
    onHandleClose, // ! Nên đổi thành onHandleDeleteFilter
    displayChartMode,
    onChangePage,
    handleChangeGroupBy,
    handleChangeColumnShowModal,
    isHiddenPagination,
    isAccessReportDiscount,
    compareDateStart,
    compareDateEnd,
    onChangeCompareDate,

    // ! Create Chart props
    query,
    queryPeriod,
    isLoadingChart,
    optionMeasure,
    onChangeYAxis,
    discount,
    autoCompleteFilterPanel,
    isLoadingAutoCompleteFilterPanel,
    onHandleChangeFilter,
    benchmark,
    optionsHeatMap,
    onChangeHeatmaps,
    onHandleChangeCheckboxInFilterTypeSingle,
    groupByFilterPanel,
    onHandleSubmitFilterWithDimension,
    reportId,
    reportName,
    optionCompare,
    dataExport,
    onHandleTriggerGroupByAndFilter,
    submitFilterV2,
    dependdata,
    dataFetchingTable,
    onHandleCloseFilterByDimension,
    measureDraw,
    onHandleChangeChartType,
    summaryDrilldown
  } = props;

  const {
    dataHeaders,
    dataRows,
  } = useTableUtils(headers, data, reportNameSystem, groupBySelected);

  if (reportNameSystem === "cas_transaction_pnl") {
    dataExport.headerreport = [0, 3, 7, 8, 9, 10, 13, 14, 15, 16];

    if (headers !== undefined) {
      dataExport.headerreport = dataHeaders;
      dataExport.data = dataRows;
    }
  };

  return (
    <div>
      <div
        className="hrv-report-mb-16 hrv-report-sticky"
        style={{ top: -1, height: "fit-content", zIndex: 123 }}
      >
        <PanelFilter
          isAccessReportDiscount={isAccessReportDiscount}
          reportNameSystem={reportNameSystem}
          groupBySelected={groupBySelected}
          groupBy={groupBy}
          handleChangeGroupBy={handleChangeGroupBy}
          onChangeDate={onChangeDate}
          dateEnd={dateEnd}
          dateStart={dateStart}
          compareDateEnd={compareDateEnd}
          compareDateStart={compareDateStart}
          onChangeCompareDate={onChangeCompareDate}
          autoCompleteFilterPanel={autoCompleteFilterPanel}
          isLoadingAutoCompleteFilterPanel={isLoadingAutoCompleteFilterPanel}
          onHandleChangeFilter={onHandleChangeFilter}
          filterTag={filterTag}
          onHandleChangeCheckboxInFilterTypeSingle={
            onHandleChangeCheckboxInFilterTypeSingle
          }
          groupByFilterPanel={groupByFilterPanel}
        />
      </div>

      {reportNameSystem === "stock_remaining_days" && <PanelStatus options={
        SCORE_CARD
      } onHandleSubmitFilter={onHandleSubmitFilterWithDimension}
        reportId={reportId}
      />}

      {isAccessReportDiscount && reportNameSystem === "sales_promotion" ? (
        <MetricsDiscount
          query={query}
          queryPeriod={queryPeriod}
          isLoading={false}
        />
      ) : (
        (<>
          {displayChartMode !== null && displayChartMode !== undefined ? (
            <>
              {SALES_METRICS.includes(reportNameSystem) ?
                <SalesMetrics reportNameSystem={reportNameSystem} /> :
                <>
                  {METRICS.includes(reportNameSystem) ?
                    <MetricsV2
                      reportNameSystem={reportNameSystem}
                      reportId={reportId}
                      isLoadingTable={isLoadingTable}
                      displayChartMode={displayChartMode}
                      dataTable={{
                        current: {
                          data,
                          headerreport: headers
                        },
                        previous: {
                          data: [],
                          headerreport: []
                        },
                      }}
                      measureDraw={measureDraw}
                      optionMeasure={optionMeasure}
                      onHandleCheckboxChart={onHandleCheckboxChart}
                      onhandleChangeChartType={onHandleChangeChartType}
                    /> :
                    <Metrics
                      startdate={dateStart}
                      enddate={dateEnd}
                      headers={headers}
                      measureSelected={measureSelected}
                      data={data}
                      displayChartMode={displayChartMode}
                      isLoading={isLoadingTable} // Loading chart bình thường theo từng loại báo cáo chưa được cấu hình gọi API riêng lẻ
                      measure={measure}
                      reportNameSystem={reportNameSystem}
                      onHandleCheckboxChart={onHandleCheckboxChart}
                      query={query}
                      queryPeriod={queryPeriod}
                      compareDateStart={compareDateStart}
                      compareDateEnd={compareDateEnd}
                      optionCompare={optionCompare}
                      isLoadingChart={isLoadingChart}
                      reportId={reportId}
                      dataFetchingTable={dataFetchingTable}
                      measureDraw={measureDraw}
                      optionMeasure={optionMeasure}
                    />}
                </>
              }</>
          ) : (
            <></>
          )}
        </>)
      )
      }

      {
        dataExport?.headerreport && dataExport?.headerreport?.length && <TableExport
          data={dataExport.data}
          headers={dataExport.headerreport}
        />
      }

      <TableReport
        reportName={reportName}
        isAccessReportDiscount={isAccessReportDiscount}
        reportNameSystem={reportNameSystem}
        canReadMACost={canReadMACost}
        headers={headers}
        data={data}
        dataLink={dataLink}
        summary={summary}
        groupBy={groupBy}
        groupAreas={groupAreas}
        measure={measure}
        groupBySelected={groupBySelected}
        measureSelected={measureSelected}
        handleChangeColumnShowModal={handleChangeColumnShowModal}
        onHandleSortTable={onHandleSortTable}
        sortValue={sortValue}
        optionDimension={optionDimension}
        filterTag={filterTag.filter((s) => {
          return !getListDisplayFilter(optionDimension).includes(s.dimension);
        })} // ! Lấy ra những cái tag filter mà không cần hiển thị lên UI
        onHandleClose={onHandleClose}
        onChangePage={onChangePage}
        isHiddenPagination={isHiddenPagination}
        isLoadingTable={isLoadingTable}
        optionMeasure={optionMeasure}
        onChangeYAxis={onChangeYAxis}
        discount={discount}
        benchmark={benchmark}
        optionsHeatMap={optionsHeatMap}
        onChangeHeatmaps={onChangeHeatmaps}
        dateEnd={dateEnd}
        dateStart={dateStart}
        reportId={reportId}
        submitFilterV2={submitFilterV2}
        dataExport={dataExport}
        onHandleTriggerGroupByAndFilter={onHandleTriggerGroupByAndFilter}
        dependdata={dependdata}
        filterSelected={filterTag}
        onHandleCloseFilterByDimension={onHandleCloseFilterByDimension}
        summaryDrilldown={summaryDrilldown}
      />
    </div >
  );
}
