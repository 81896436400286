import useSWRInfinite from 'swr/infinite';

import { useFetchJson } from "@haravan/reactapp";
import { buildQueryString } from "helpers/helpers";

const REPORT_URI = import.meta.env.VITE_APP_API_SERVICES + "/";
const REPORT_CALL_URI_V2 = REPORT_URI + `reports/`;
const METHOD = "GET";
const autocompletetype = "auto_complete";

/**
 * @Hook Load More thông tin cho autocomplete
 * @Use Báo cáo Công nợ khách hàng
 */
export const useLoadMoreAutoComplete = ({
    preventFetch,
    dimension,
    reportId,
    keyword,
}) => {
    const useHRVSWR: any = useSWRInfinite;
    const fetchJson = useFetchJson();

    const fetcher = (url: string) => {
        return fetchJson(url, {
            method: METHOD,
            headers: {
                "Content-Type": "application/json",
            }
        });
    };

    let url = `${REPORT_CALL_URI_V2}${reportId}/${autocompletetype}`;

    const getKey = (pageIndex, previousPageData) => {
        // Prevent Data don't fetching API
        if (preventFetch) return null;

        // Reached the end of Page
        if (previousPageData && !previousPageData.data.length) return null;

        const query = buildQueryString({
            dimension,
            query: keyword.toLocaleLowerCase().trim(),
            currentpage: pageIndex + 1,
            pagesize: 20,
        });

        return `${url}${query}`
    }

    return useHRVSWR(
        getKey,
        fetcher,
        {
            revalidateOnFocus: false,
            refreshInterval: 0,
            revalidateIfStale: true,
            initialSize: 1,
            persistSize: false, // Nếu false => khi mutate không gọi những page trước đó (Ví dụ loadmore tới page 8 thì khi mute sẽ gọi 8 api nếu false gọi 1)
            parallel: false,
            revalidateFirstPage: false,
        }
    );
}