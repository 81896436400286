import RenderFilterByDefault from "./ByDefault";
import RenderFilterByDiscount from "./ByDiscount";
import RenderFilterByDetailDiscount from "./ByDetailDiscount";
import { useStickyCalendar } from "hooks/useStickyCalendar";

export default function PanelFilter({
  reportNameSystem,
  groupBySelected,
  handleChangeGroupBy,
  groupBy,
  onChangeDate,
  dateStart,
  dateEnd,
  isAccessReportDiscount,
  compareDateStart,
  compareDateEnd,
  onChangeCompareDate,
  autoCompleteFilterPanel,
  isLoadingAutoCompleteFilterPanel,
  onHandleChangeFilter,
  filterTag,
  onHandleChangeCheckboxInFilterTypeSingle,
  groupByFilterPanel,
}: any) {

  useStickyCalendar({ id: "report_detail_header", threshold: 0 })

  // Báo cáo tồn kho thì không có filter thời gian. Lấy các giá trị tồn kho ở thời điểm hiện tại
  if (reportNameSystem === "stock_onhand") {
    return <></>;
  }

  return (
    <div
      id="report_detail_header"
      className="hrv-report-whitebox-no-bs hrv-report-px-16 hrv-report-py-8 report_detail_header"
    >
      {reportNameSystem === "sales_promotion" ? (
        <RenderFilterByDiscount
          onChangeDate={onChangeDate}
          dateStart={dateStart}
          dateEnd={dateEnd}
          compareDateEnd={compareDateEnd}
          compareDateStart={compareDateStart}
          onChangeCompareDate={onChangeCompareDate}
        />
      ) : (
        reportNameSystem === "sales_overview_promotion" ?
          (<RenderFilterByDetailDiscount
            onChangeDate={onChangeDate}
            dateStart={dateStart}
            dateEnd={dateEnd}
            compareDateEnd={compareDateEnd}
            compareDateStart={compareDateStart}
            onChangeCompareDate={onChangeCompareDate}
            autoCompleteFilterPanel={autoCompleteFilterPanel}
            filterTag={filterTag}
            onHandleChangeFilter={onHandleChangeFilter}
          />)
          :
          (<RenderFilterByDefault
            reportNameSystem={reportNameSystem}
            groupBySelected={groupBySelected}
            handleChangeGroupBy={handleChangeGroupBy}
            groupBy={groupBy}
            onChangeDate={onChangeDate}
            dateStart={dateStart}
            dateEnd={dateEnd}
            autoCompleteFilterPanel={autoCompleteFilterPanel}
            isLoadingAutoCompleteFilterPanel={isLoadingAutoCompleteFilterPanel}
            onHandleChangeFilter={onHandleChangeFilter}
            filterTag={filterTag}
            onHandleChangeCheckboxInFilterTypeSingle={
              onHandleChangeCheckboxInFilterTypeSingle
            }
            groupByFilterPanel={groupByFilterPanel}
          />)
      )}
    </div>
  );
}
