import { formatNumber, valueHandler } from "helpers/helpers";
import { useTrans } from "locales/hook";

type Props = {
    data: any;
    headers: any;
}

export const TableExport: React.FC<Props> = (props: Props) => {
    const { data, headers } = props;

    const t = useTrans();
    const type = ['timestamp', "dayofweek", 'hourofday'];

    const defaultHeader = () => {
        return <tr>
            {headers?.map((head, idx) => {
                let name = t(head.fieldName);

                if (
                    head.fieldName === "timeid" ||
                    head.fieldName === "OrderDate" ||
                    head.fieldName === "CreatedDate" ||
                    head.fieldName === "TranDate" ||
                    head.fieldName === "PackedDate"
                ) {
                    name = head.name;
                }

                return (
                    <th id={`${idx}`}>
                        {name}
                    </th>
                );
            })}
        </tr>
    }

    const defaultBody = () => {
        return <tbody>
            {
                data?.map((body, idx) => {
                    return (
                        <tr key={`tr_table_r_${idx}`}>
                            {body.map((value, id) => {
                                const format = headers[id]?.dataFormat || "text";
                                const name = headers[id]?.name || "";
                                let displayByTime = "";

                                switch (format) {
                                    case "link": {
                                        if (name === "ProductId" || name === "VariantId") {
                                            if (value === "--") {
                                                value = t("Optional_product");
                                            } else {
                                                value = t(value.trim());
                                            }
                                        }

                                        if (name === "CustomerId") {
                                            if (value === "guest") {
                                                value = t("guest");
                                            }
                                        }
                                        if (name === "DiscountId") {
                                            if (value === "redeem") {
                                                value = t("redeem");
                                            }
                                        }
                                        break;
                                    }

                                    case "numberday":
                                        displayByTime = `${formatNumber(value)} ${t("ngày")}`;
                                        break;

                                    case "numberhour":
                                        displayByTime = `${value} ${t("giờ")}`;
                                        break;
                                    default:

                                        if (name === "CustomerGender") {
                                            value = t(value);
                                        }

                                        if (name === "CustomerType") {
                                            value = t(value);
                                        }

                                        if (name === "DiscountMethod") {
                                            value = t(`DiscountMethod_value_${value}`)
                                        }

                                        if (name === "DiscountType") {
                                            value = t(`DiscountType_value_${value}`)
                                        }
                                        if (name === "CarrierStatus") {
                                            value = t(`CarrierStatus_value_${value}`)
                                        }
                                        if (name === "CarrierCODStatus") {
                                            value = t(`CarrierCODStatus_value_${value}`)
                                        }
                                        if (name === "CustomerAgeGroup") {
                                            value = t(`CustomerAgeGroup_value_${value}`)
                                        }
                                        if (name === "CustomerSegment") {
                                            value = t(`CustomerSegment_value_${value}`)
                                        }

                                        break;
                                }

                                return (
                                    <td key={`td_table_r_${idx}_${id}`}>
                                        {type.includes(format) ?
                                            <>{valueHandler(format, name, value)}</>
                                            :
                                            <>
                                                {format === "numberday" || format === "numberhour" ?
                                                    <>{displayByTime}</> :
                                                    <>
                                                        {format === "money" ? Math.round(Number(value)) : value}
                                                    </>}
                                            </>}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
        </tbody>
    }

    return <table id="table-print"
        style={{
            display: 'none'
        }}
    >
        {defaultHeader()}

        {defaultBody()}
    </table>
}