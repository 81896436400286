/**
 * Custom hooks dùng riêng cho Page Report Detail
 */

import { useEffect, useState } from "react"
import useApp from "hooks/useApp";
import { useFetchJson } from "@haravan/reactapp";
import { isCallSummaryApi, isEmpty } from "helpers/helpers";
import md5 from "helpers/md5";

/**
 * * Gọi dữ liệu summary cho table
 * @param reportInfo : thông tin chi tiết của report
 * @param payloadCallTableData : payload để gọi dữ liệu cho report bao gồm (filters, groupby,metrics, daterange, orderby)
 */
import useSWR from "swr";
import useSWRMutation from 'swr/mutation'

const REPORT_URI_V2 = import.meta.env.VITE_APP_API_SERVICES + `/reports/`;
const REPORT_URI = import.meta.env.VITE_APP_API_SERVICES;

export const useSummaryDetail = (isEnabled, reportInfo, payloadCallTableData): any => {
    const useHRVSWR: any = useSWR;

    const key = md5(JSON.stringify(payloadCallTableData));
    const fetchJson = useFetchJson();

    const { betaFeatures, setIsDisplaySummary } = useApp();
    const [isPrevent, setIsPrevent] = useState(true);

    // ! Gọi API summary (mỗi lần payloadCallTableData được cập nhật thì Summary sẽ được gọi lại)
    const useEffectSummary = (effect: React.EffectCallback) => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        useEffect(effect, [reportInfo, payloadCallTableData])
    }

    useEffectSummary((): any => {
        if (!isEmpty(reportInfo) && payloadCallTableData) {
            const isDisplaySummary =
                isCallSummaryApi({
                    reportNameSystem: reportInfo.reportNameSystem,
                    betaFeatures,
                    metrics: reportInfo?.lstReportScreenMeasure,
                    filtersDefault: payloadCallTableData.filters,
                    payload: payloadCallTableData
                });

            setIsDisplaySummary(isDisplaySummary);
            setIsPrevent(!isDisplaySummary);
        }
    });

    const fetcher = (url: string) => fetchJson(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(payloadCallTableData),
    });

    const { data, mutate } = useHRVSWR(
        isPrevent || !isEnabled ? null : `${REPORT_URI_V2}${reportInfo.id}/summary?key=${key}`,
        fetcher,
        {
            revalidateOnFocus: false,
            refreshInterval: 0,
            dedupingInterval: 300
        },
    );

    return { data: data?.data, mutate }
}

export const useRFMPointApi = (): any => {
    const useHRVSWR: any = useSWR;
    const fetchJson = useFetchJson();

    const fetcher = (url: string) => fetchJson(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    });


    const { isLoading, data, mutate } = useHRVSWR(
        `${REPORT_URI}/shop/segments`,
        fetcher,
        {
            revalidateOnFocus: false,
            refreshInterval: 0,
            dedupingInterval: 300
        },
    );

    return { isLoading, data: data?.data, mutate }
}

export const useSubmitRFM = ({ onSuccess }) => {
    const useHRVSWR: any = useSWRMutation;
    const fetchJson = useFetchJson();

    const fetcher = (url: string, { arg }) => fetchJson(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(arg)
    });


    const { trigger, isMutating } = useHRVSWR(
        `${REPORT_URI}/shop/segments`,
        fetcher,
        {
            revalidateOnFocus: false,
            refreshInterval: 0,
            dedupingInterval: 300,
            onSuccess: (data, key, config) => {
                return onSuccess(data, key, config)
            }
        },

    );

    return { trigger, isMutating }
}