import { useTrans } from "locales/hook";
import useApp from "hooks/useApp";

import { valueHandler } from "./helpers";

export function useTableUtils(headers, data, reportNameSystem, groupBySelected) {
    const t = useTrans();
    const { isDisplaySummary } = useApp();
    let dataHeaders = [...(headers || [])];
    let dataRows = data;
    let headingRows: Array<any> = [];
    let subHeadingRows: Array<any> = [];
    let sortable = true;
    let isReportPNL = false;
    let displaySummary = true;

    const buildPNLeaders = (rows) => {
        var pnlHeaders = [
            {
                dataFormat: "text",
                name: t("report_metrics"),
                fieldName: t("report_metrics"),
            },
        ];

        rows?.forEach((row) => {
            pnlHeaders.push({
                dataFormat: "money",
                fieldName: valueHandler(
                    "timestamp",
                    groupBySelected[0].groupPropertyName,
                    row[0]
                ),
                name: valueHandler(
                    "timestamp",
                    groupBySelected[0].groupPropertyName,
                    row[0]
                ),
            });
        });

        return pnlHeaders;
    };

    const buildPNLRows = (rows, dataHeaders) => {
        var pnlRows: any[] = [];

        headers?.forEach((header, hIndex) => {
            headers[hIndex].dataFormat = "text";
            if (hIndex > 0) {
                headers[hIndex].dataFormat = "money";
                var item = Array(dataHeaders.length).fill("");
                item[0] = t(header.name);
                pnlRows.push(item);
            }
        });

        rows?.forEach((row, rIndex) => {
            row.forEach((col, cIndex) => {
                if (cIndex > 0) {
                    pnlRows[cIndex - 1][rIndex + 1] = col;
                }
            });
        });

        return pnlRows;
    };

    if (
        [
            "stock_transactionhistory_adv",
            "stock_transactionhistory_alltime",
            "payments_deb_supplier",
            "payments_deb_customer"
        ].includes(reportNameSystem)
    ) {
        sortable = false
    };

    if (reportNameSystem === "cas_transaction_pnl") {
        isReportPNL = true;
        sortable = false;
        headingRows = [0, 3, 7, 8, 9, 10, 13, 14, 15, 16];
        subHeadingRows = [1, 2, 4, 5, 6, 11, 12];
        if (headers !== undefined) {
            dataHeaders = buildPNLeaders(data);
            dataRows = buildPNLRows(data, dataHeaders);
        }
    }

    if (reportNameSystem === "cas_transaction_pnl") {
        isReportPNL = true;
        sortable = false;
        headingRows = [0, 3, 7, 8, 9, 10, 13, 14, 15, 16];
        subHeadingRows = [1, 2, 4, 5, 6, 11, 12];

        if (headers !== undefined) {
            dataHeaders = buildPNLeaders(data);
            dataRows = buildPNLRows(data, dataHeaders);
        }
    }

    displaySummary = isDisplaySummary;

    return {
        dataHeaders,
        dataRows,
        isReportPNL,
        sortable,
        headingRows,
        subHeadingRows,
        displaySummary
    }
}